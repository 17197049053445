<template>
    <div class="practical-container">
        <div class="practical-content">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item v-if="role === 3">
                    <span class="color-blue" @click="goBackTwo">班级管理</span>
                </el-breadcrumb-item>
                <el-breadcrumb-item v-if="role === 4">
                    <span class="color-blue" @click="goBack">我的班级</span>
                </el-breadcrumb-item>
                <el-breadcrumb-item v-if="role === 3">
                    <span class="color-blue" @click="goBack">{{className}}</span>
                </el-breadcrumb-item>
                <el-breadcrumb-item>实训列表</el-breadcrumb-item>
            </el-breadcrumb>
            <div class="practical-list">
                <div class="select course-list" v-if="recordId === ''">
                    <span class="select-text">课程筛选：</span>
                    <el-select v-model="courseValve" placeholder="请选择" style="width: 150px" clearable @change="selectCourse">
                        <el-option
                                v-for="item in courseList"
                                :key="item.course_id"
                                :label="item.course_name"
                                :value="item.course_id">
                        </el-option>
                    </el-select>
                </div>
                <el-table :data="practicalList" border style="width: 100%; flex: 1;" height="1%"  size="medium" :header-cell-style="{fontWeight: 'normal', color: '#333', background: '#f5f7fa', textAlign: 'center'}" :cell-style="{fontSize: '12px',color: '#333', textAlign: 'center'}">
<!--                    <el-table-column label="封面" width="233">
                        <template slot-scope="scope">
                            <img :src="scope.row.projectInfo.project_cover" alt="">
                        </template>
                    </el-table-column>-->
                    <el-table-column label="内容">
                        <template slot-scope="scope">
                            <span>{{scope.row.projectInfo.project_title}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="创建人" width="100">
                        <template slot-scope="scope">
                            <span>{{scope.row.teacherInfo.t_name}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="剩余时间" width="130">
                        <template slot-scope="scope">
                            <span v-if="scope.row.residue_time === '实训已结束'">{{scope.row.residue_time}}</span>
                            <div v-else >
                                <span>{{scope.row.residue_time.day}}: </span>
                                <span>{{scope.row.residue_time.hour}}: </span>
                                <span>{{scope.row.residue_time.min}}: </span>
                                <span>{{scope.row.residue_time.sec}}</span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="create_time" label="发布时间" width="140"></el-table-column>
                    <el-table-column label="操作" width="180" align="center">
                        <template slot-scope="scope">
                            <el-button size="small" type="primary" @click="practicalDetail(scope.row)">查看详情</el-button>
                            <el-button size="small" type="danger" @click="deleteRow(scope.row.id)" v-if="role === 3">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination class="pages-center" background
                    :current-page.sync="practicalPages.currentPageNum"
                    :page-size="practicalPages.eachPageNum"
                    :total="practicalPages.total"
                    layout="prev, pager, next, jumper"
                    @current-change="practicalCurrentChange">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "practicalList",
        data() {
            return {
                //班级名称，课程id，上课记录id，角色id
                className: this.$route.query.className || '',
                classId: Number(this.$route.query.classId) || '',
                recordId: Number(this.$route.query.recordId) || '',
                role: Number(localStorage.getItem('role')) || '',
                //课程选择
                courseValve: '',
                courseList: [],
                // course_id: 0,
                //实训列表
                practicalList: [],
                //分页
                practicalPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0,
                },
            }
        },
        created() {
            this.getCourses();
        },
        methods: {
            //筛选
            selectCourse() {
                this.getPracticalList();
            },
            //课程列表
            getCourses() {
                if (this.role === 4) {
                    let params = {
                        parent_id: 'no',
                        type: 1,
                        sort: 1
                    }
                    this.$shttp.axiosGetBy(this.$api.admin_courseLists, params, (res) => {
                        if (res.code === 200) {
                            this.courseList = res.list;
                            this.courseValve = res.list[0].course_id;
                            if (this.courseValve) {
                                this.getPracticalList();
                            }
                        } else {
                            this.$message.error(res.msg);
                        }
                    }, (err) => {
                        console.log(err);
                    });
                } else if (this.role === 3) {
                    this.$shttp.axiosGet(this.$api.admin_showTeaCourse, (res) => {
                        if (res.code === 200) {
                            this.courseList = res.data.course_list;
                            this.courseValve = res.data.course_list[0].course_id;
                            if (this.courseValve) {
                                this.getPracticalList();
                            }
                        } else {
                            this.$message.error(res.msg);
                        }
                    }, (err) => {
                        console.log(err);
                    })
                }
            },
            //实训列表
            getPracticalList() {
                let param = {
                    page: this.practicalPages.currentPageNum,
                    limit: this.practicalPages.eachPageNum,
                    course_id: this.courseValve
                }
                if (this.classId) {
                    param.c_id = this.classId;
                }
                if (this.recordId) {
                    param.course_record_id = this.recordId;
                }
                this.$shttp.axiosGetBy(this.$api.drill_showDrillList, param, (res) => {
                    if (res.code === 200) {
                        this.practicalList = res.data.data;
                        this.practicalPages.total = res.data.total;
                    } else {
                        this.$message.error(res.msg);
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            //查看详情
            practicalDetail(row) {
                let status = null
                if (row.residue_time === '实训已结束') {
                    status = 1
                    this.$router.push({
                        path: '/teacherClass/practicalDetail',
                        query: {
                            p_id : row.project_id,
                            sx_id : row.id,
                            className: this.className,
                            status: status
                        }
                    });
                } else {
                    this.$router.push({
                        path: '/teacherClass/practicalDetail',
                        query: {
                            p_id : row.project_id,
                            className: this.className,
                            sx_id: row.id
                        }
                    });
                }
            },
            //删除
            deleteRow(id) {
                this.$confirm('是否删除该实训，删除完将无法恢复', '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$shttp.axiosGetBy(this.$api.drill_delDrill, {id: Number(id)}, (res) => {
                        if (res.code === 200) {
                            this.$message.success(res.msg);
                            this.getPracticalList();
                        } else {
                            this.$message.warning(res.msg)
                        }
                    }, (err) => {
                        console.log(err)
                    })
                }).catch(() => {
                    this.$message.info('已取消删除')
                });
            },
            //分页
            practicalCurrentChange(val) {
                this.practicalPages.currentPageNum = val;
                this.getPracticalList();
            },
            goBack() {
                this.$router.go(-1)
            },
            goBackTwo() {
                this.$router.go(-2)
            },
            goBackThree() {
                this.$router.go(-3)
            },
            goBackFour() {
                this.$router.go(-4)
            }
        }
    }
</script>

<style scoped lang="scss">
    .practical-container {
        height: calc(100% - 40px);
        background: #F2F2F2;
        padding: 20px 0;
    .practical-content {
        height: 100%;
        display: flex;
        flex-direction: column;
        width: 1200px;
        margin: 0 auto;
    }
    }
    ::v-deep .el-breadcrumb {
        margin-bottom: 20px;
        .el-breadcrumb__inner {
            color: #000;
        }
    }
    .practical-list {
        display: flex;
        flex: 1;
        flex-direction: column;
        background-color: #fff;
        ::v-deep .el-table--border td:first-child .cell {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 233px;
            height: 100px;
            margin: 0 auto;
            padding: 0;
            img {
                max-width: 100%;
                max-height: 100%;
            }
        }
    }
    .course-list {
        margin: 20px;
    }
    .pages-center {
        margin-bottom: 20px;
    }
</style>